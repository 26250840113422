const firebaseConfig = {

    apiKey: "AIzaSyBCRwTjCCnllIulLjGHyr-2JBM7dtQ_5mI",
    authDomain: "cyber-security-6ebc1.firebaseapp.com",
    projectId: "cyber-security-6ebc1",
    storageBucket: "cyber-security-6ebc1.appspot.com",
    messagingSenderId: "811200835843",
    appId: "1:811200835843:web:322b39fbec4b07b08e3936"

};
export default firebaseConfig;