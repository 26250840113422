import React from 'react';
import Contacts from '../../Components/Contacts/Contacts'


const VideoEditing = () => {
    return (
        <div>
            <div className='w-100 mx-auto'>
                <div class="card text-bg-dark w=100 mx-auto ">
                    <img src="image/banner-poster/video.jpg" class="card-img" alt="..." />

                </div>
                <div class="card m-3 w-75 mx-auto p-3 shadow-lg">
                    <div class="row g-0">

                        <div class="col-md-6">
                            <div class="card-body">
                                <h5 class="card-title">"Professional Video Editing And Rendering Services"</h5>
                                <p class="card-text">Transforming Your Footage into Stunning Videos. Let's Elevate Your Content!
                                    <ul>
                                        <li>Video Editing</li>
                                        <li>Music and Sound Effects</li>
                                        <li>Motion Graphics</li>
                                        <li>Special Effects</li>
                                        <li>Social Media Optimization</li>

                                    </ul>

                                </p>
                                <p class="card-text"><small class="text-body-secondary">Let's collaborate to make your visual content stand out! Contact me today to discuss your project.




                                </small></p>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <img src="image/banner-poster/video banner.jpeg" class="img-fluid w-100 mx-auto rounded-start" alt="..." />
                        </div>
                    </div>
                </div>
                <div class="card mb-3 w-75 mx-auto">
                    <img src="image/banner-poster/video price image.avif" class="card-img-top" alt="..." />
                    <div class="card-body">
                        <h1 class="card-title text-center">Sky Scraper Video Content Service Pricing details</h1>
                        <table class="table w-50 mx-auto">
                            <thead>
                                <tr>
                                    <th scope="col">Package</th>
                                    <th scope="col">Services</th>
                                    <th scope="col">Pricing</th>
                                    <th scope="col">Delivery Duration</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <th scope="row">Basic Package</th>
                                    <td>
                                        <li>Basic (Knock to discuss) Basic 1 min video edit</li>
                                        <li>Add-ons available as required(sound design and mixing)</li>



                                    </td>
                                    <td>76$</td>
                                    <td>3 days</td>
                                </tr>
                                <tr>
                                    <th scope="row">Standard Package</th>
                                    <td>
                                        <li>Standard (Knock to negotiate) 5 min video edit</li>
                                        <li>Add-ons available as required(sound design and mixing)</li>
                                        <li>Color Correction</li>
                                        <li>Animated texts</li>
                                        <li>Add-ons available as required</li>



                                    </td>
                                    <td>175$</td>
                                    <td>6 days</td>
                                </tr>
                                <tr>
                                    <th scope="row">Premium Package</th>
                                    <li>Premium (Knock to discuss) 10 min video edit</li>
                                    <li>Motion graphics</li>
                                    <li>Audio Fix & All the Trendy effects</li>
                                    <li>sound design and mixing</li>
                                    <td>160$</td>
                                    <td>7 days</td>
                                </tr>

                            </tbody>
                        </table>

                    </div>
                </div>
                <Contacts></Contacts>

            </div>

        </div>
    );
};

export default VideoEditing;